import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { mingPao, singTao, singTao2, youngPost } from '../../media';

const Media = () => {
  const title = 'Media publications';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <ul>
        <li>
          <a href={singTao}>星島日報專欄馮禮慈先生對本計劃的介紹</a>
        </li>
        <li>
          <a href={mingPao}>明報《教得樂》周刋對本計劃的專題訪問</a>
        </li>
        <li>
          <a href={youngPost}>
            Interview by the South China Morning Post – Young Post
          </a>
        </li>
        <li>
          <a href={singTao2}>星島日報《武論尊》對本計劃的專題訪問</a>
        </li>
      </ul>
    </Layout>
  );
};

export default Media;
